<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <div :style="collapseBtn" class="topBtn">
      <img
        class="image-link"
        width="40px"
        height="40px"
        src="img/icons/expand.png"
        @click="toggleSidebar"
      />
    </div>
    <side-barMobile
      :style="displayMobileSideBar"
      :background-color="sidebarBackground"
      short-title="hds-code2"
      title="HDS CODE 2"
    >
      <template slot="switcher">
        <div style="text-align: right">
          <img
            class="image-link"
            width="15px"
            height="15px"
            src="img/icons/collapse.png"
            @click="toggleSidebar"
          />
        </div>
      </template>
      <template slot="links">
        <perfect-scrollbar style="max-height: 250px">
          <sidebar-item
            v-for="item in AccountStore.menuItems"
            :documentType="item.type"
            :key="item.id"
            :link="item.link"
            :mobile="true"
          />
        </perfect-scrollbar>
      </template>
    </side-barMobile>
    <side-bar
      :style="displayWebSideBar"
      :background-color="sidebarBackground"
      short-title="hds-code2"
      :class="collapseSwitch"
    >
      <template slot="switcher">
        <div style="text-align: right">
          <img
            class="image-link"
            width="15px"
            height="15px"
            src="img/icons/collapse.png"
            @click="toggleSidebar"
          />
        </div>
      </template>
      <template slot="links">
        <perfect-scrollbar>
          <sidebar-item
            v-for="item in AccountStore.menuItems"
            :documentType="item.type"
            :key="item.id"
            :link="item.link"
            :mobile="false"
          />
        </perfect-scrollbar>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground" :class="collapseSwitchMain">
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import AccountStore from "../store/accountStore";
import { auth } from "../store/authModule";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Vue from "vue";
import { startTVweb } from "../../public/teamview/teamview_web.js";
import { eventBus } from "../main.js";
import { checkIfValidLicence } from "../helper/tvWebLicenseChecker.js";
import TvWebDataLoader from "../helper/tvWebDataLoader.js";
import i18n from "../i18n.js";

Vue.use(PerfectScrollbar);
const app = new Vue();

export default {
  components: {
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      AccountStore: AccountStore.data,
      displayMobileSideBar: "display:none",
      displayWebSideBar: "display:none",
      linksHeight: null,
      resizeTimer: null,
      resizeTimerMedview: null,
      collapseSwitch: false,
      collapseSwitchMain: "mainUncollapsed",
      collapseBtn: "display:none",
    };
  },

  updated() {
    this.checkCredentials();
  },

  async mounted() {
    this.checkCredentials();
    this.adjustDisplay();
    var me = this;
    const loader = new TvWebDataLoader();

    eventBus.$on("dataReadyForTvWeb", (array) => {
      
      let url = window.location.origin + "/hdscode2/teamview/teamview_web.html";
      const user = JSON.parse(localStorage.getItem('user'));
      let jwt = user;
      
      const URLConfig = 
        {         
          "apiBaseUrl": Vue.prototype.$serverUrl + "/v1/tvweb",
          "renderServer": Vue.prototype.$rsUrl,
          "tvwebUrl": url
        };

      let studyList = JSON.parse(JSON.stringify(array));
      startTVweb({jwt, studyList, URLConfig});

    });

    eventBus.$on("tvWebStudyLoadError", () => {
      app.$alert(i18n.t("reUploadText"));
    });

    if (window.localStorage.getItem("teamViewDisplay") == "true") {
      if ((await checkIfValidLicence()) == true) {              
        loader.prepareTvWebPayload();
      } else {
        localStorage.setItem("teamViewDisplay", false);
        me.$fire({
          title: "Invalid licence",
          text: "Your teamView web licence is not valid",
          type: "error",
        });
        return;
      }
      return;
    }

    this.$nextTick(() => {
      var me = this;

      window.addEventListener("resize", function () {
        clearTimeout(me.resizeTimerMedview);
        me.resizeTimerMedview = setTimeout(me.onResize, 1000);
      });
    });
  },

  methods: {
    adjustDisplay() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        this.displayMobileSideBar = "display:block";
        this.displayWebSideBar = "display:none";
        this.collapseSwitchMain = "mainUncollapsedMobile";
      } else {
        this.displayWebSideBar = "display:block";
        this.displayMobileSideBar = "display:none";
        this.collapseSwitchMain = "mainUncollapsed";
      }
    },

    toggleSidebar() {
      if (this.collapseSwitch == false) {
        this.collapseSwitch = true;
        this.collapseSwitchMain = "mainCollapsed";
        this.collapseBtn = "display: block";
      } else {
        this.collapseSwitch = false;
        this.collapseSwitchMain = "mainUncollapsed";
        this.collapseBtn = "display: none";
      }
    },
    onResize() {
      this.adjustDisplay();
    },
    checkCredentials() {
      let jwtUser = JSON.parse(localStorage.getItem("user"));
      if (!jwtUser || new Date().getTime() > jwtUser.expiresAt) {
        auth.mutations.logout();
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style>
.mainCollapsed {
  margin-left: 0px !important;
}

.mainUncollapsed {
  margin-left: 0;
}

.mainUncollapsedMobile {
  margin-left: 0px !important;
}

.topBtn {
  position: absolute;
  width: 20;
  height: 20;
  left: 15px;
  bottom: 20px;
  z-index: 9999;
}

.image-link {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s;
}

.image-link:hover {
  opacity: 0.3;
}
</style>
