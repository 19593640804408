
/**
 * @typedef URLConfig
 * @type {object}
 * @property {string} [apiBaseUrl] - base Url for external endpoints - optional
 * @property {string} renderServer - url to renderserver 
 * @property {number} tvwebUrl - url to teamVIEW web client html page. e.g. 'http://localhost/teamview_web/STABLE/teamview_web.html'
 */

/**
 * @typedef JWT
 * @type {object}
 * @property {string} access_token - jwt access_token
 * @property {string} token_type - Token type. E.g. 'Bearer'
 * @property {number} expires_in - The expiration time of the access token in seconds
 * @property {string } [refresh_token] - refresh token
 */

/**
 * Starts teamVIEW web viewer.
 * tvweb opens in a new Tab. If tvweb is already open in a tab it will reuse it
 * To use tvweb with several monitors the tvweb Browser extension is needed.
 * @param tvwebParams 
 * @param {JWT} tvwebParams.jwt [jwt=null] - Jwt token to authenticate to Endpoints -- unused
 * @param {Array} tvwebParams.studyList - List of studyJsons to be opened in tvweb
 * @param {URLConfig} tvwebParams.URLConfig - contains URLS for external communication. Should contain endpoint for renderserver and the base URL for the integration API
 * @param {Object} [tvwebParams.adminSettings] - contains administrator settings for like search and renderquality - teamportal only
 * @returns Promise that resolves to true, when tvweb has successfully been opened
 */
export async function startTVweb(tvwebParams) {

	// validate input parameters
	if (!tvwebParams.studyList?.length){
		throw "No studies provided";
	}

	if (!tvwebParams.URLConfig){
		throw "No URLConfig provided";
	}
	if (!tvwebParams.URLConfig?.renderServer){
		throw '"renderServer" endpoint is mandatory';
	}
	if (!tvwebParams.URLConfig?.tvwebUrl){
		throw '"tvweb" url is mandatory';
	}

	// Try to create a URL Object from each endpoint. 
	// Throws if url string has invalid url format
	Object.values(tvwebParams.URLConfig).forEach(ep => {
		new URL(ep);
	});


	// Needs to be defined as global variables, otherwise it is not correctly updated
	// in the Broadcast Channel callback on subsequent calls
	window.tvwebParams =  tvwebParams;

	if (document.getElementById('tvwebBrowserExtensionInstalled')) {
		await sendMessageToExtension("startTVweb", { tvwebUrl: tvwebParams.URLConfig.tvwebUrl, tvwebParams: window.tvwebParams });
	}
	else {
		const bc = new BroadcastChannel("twModuleBC");
		await new Promise((resolve, reject) => {
			bc.onmessage = (msg => {
				switch (msg?.data.action) {
					case 'pageLoaded':
						bc.postMessage({ action: 'startTVweb', tvwebParams: window.tvwebParams });
						resolve(bc);
						break;
				}
			});


			const winProxy = window.open(tvwebParams.URLConfig.tvwebUrl, '_tvweb');
			if (!winProxy) {
				reject("tvweb window could not be opened");
			}
		})
		bc.close(); // BroadcastChannel needs to be closed to be garbage collected
	}
	return true;
}

/**
 * Copied from extensionInterface.js
 * Sends Messages to browser Extension
 * @param {string} action Action to be performed by extension
 * @param {Object} data Parameters for the action
 * @returns Promise with response from extension
 */
function sendMessageToExtension(action, data){
    return new Promise((resolve, reject) => {
		if (!document.getElementById('tvwebBrowserExtensionInstalled'))	{
            reject(new Error("tvweb browser extension is not installed"));
            return;
        }
        document.addEventListener("fromEx_" + action, (event) => {
            if (event?.detail?.errMsg) {
                reject(new Error(event.detail.errMsg));
            }
            else {
                resolve(event?.detail);
            }
        }, { once: true });
        window.dispatchEvent(new CustomEvent("toTVwebExtension", { detail: {action: action, ...data }}));
    });
}